<template>
  <company-form
    :showDeleteButton="false"
    v-on:submit="onSubmit"
    :isSubmitting="isSubmitting"
  >
  </company-form>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/modules/breadcrumbs";
import { mapActions, mapGetters } from "vuex";
import Api from "@/services/api";
import CompanyForm from "@/components/forms/CompanyForm.vue";

export default {
  name: "AddCompany",
  components: { CompanyForm },
  data() {
    return {
      isSubmitting: false
    };
  },
  computed: {
    ...mapGetters({
      isMerchant: "isMerchant"
    })
  },
  mounted() {
    const endRoute = this.isMerchant ? "ROUTES.BRANCHES" : "ROUTES.COMPANIES";

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("ROUTES.CORPORATE") },
      { title: this.$t(endRoute) },
      { title: this.$t("MENU.NEW") }
    ]);
  },
  beforeRouteLeave(to, from, next) {
    this.clearSelectedCompany();
    next();
  },
  methods: {
    ...mapActions({
      clearSelectedCompany: "company/CLEAR_SELECTED_COMPANY",
      toggleCompanyActiveStatus: "company/TOGGLE_COMPANY_ACTIVE_STATUS"
    }),
    onSubmit(company) {
      const successTitle = this.$t("FORM.SUCCESS_TITLE");
      const errorTitle = this.$t("FORM.ERROR_TITLE");
      const successMessage =
        this.$t("COMPANY.COMPANY") + this.$t("FORM.SUBMITTED");
      const errorMessage = this.$t("FORM.ERROR");
      this.isSubmitting = true;
      Api.post("companies", company)
        .then(() => {
          this.$root.$bvToast.toast(successMessage, {
            title: successTitle,
            variant: "success",
            autoHideDelay: 5000
          });
          this.$router.replace({ name: "company-companies" });
        })
        .catch(() => {
          this.$root.$bvToast.toast(errorMessage, {
            title: errorTitle,
            variant: "danger",
            autoHideDelay: 5000
          });
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    }
  }
};
</script>
